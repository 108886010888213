@import '../../break-points.scss';

.top-selection {
  height: 100vh;
  display: flex;
  background-color: white;
  align-items: center;
  position: relative;
  justify-content: center;
  &-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include sm-mobile {
      padding-top: 5em;
    }
    @include tablet {
      padding-top: 2em;
    }

    @include sm-laptop {
      padding-top: 5em;
    }
    &-item {
      cursor: pointer;
      border: 1px dashed #c4c4c4;
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 20px;
      min-width: 300px;
      max-width: 300px;
      font-family: Cairo;
      height: 8em;
      &:active {
        background-color: #f5f5f5;
      }
      &:hover {
        background-color: #f5f5f5;
      }
      // &:hover {
      //   min-width: 305px;
      //   max-width: 305px;
      // }
      @include sm-mobile {
        margin: 0em;
        margin-top: 8px;
      }
      @include tablet {
        padding-top: 8px;
        margin: 1em;
      }

      @include sm-laptop {
        margin: 2em;
      }
    }
  }
  &-holder {
    position: absolute;
    font-family: Montserrat;
    @include sm-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-left: 0em;
      flex-direction: column;
      left: 0;
      right: 0;
      top: 10vh;
      width: 98vw;
    }
    @include tablet {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-align: center;
      padding-left: 0em;
      left: 8px;
      right: 8px;
      top: 10vh;
      width: 95vw;
    }

    @include sm-laptop {
      top: 20vh;
      width: 50vw;
      text-align: left;
      left: 5em;
      right: 5em;
    }
    &-title {
      width: 100%;
      @include sm-mobile {
        font-size: 22px;
      }
      @include tablet {
        font-size: 40;
      }

      @include sm-laptop {
        font-size: 40px;
      }
    }
    &-notes {
      font-size: 13px;
      font-weight: lighter;
      line-height: 25px;
      padding-top: 2em;
      @include sm-mobile {
      }

      @include tablet {
      }

      @include sm-laptop {
        padding-top: 5px;
        width: 50vw;
      }
    }
  }
}

.service {
  height: 100%;
  min-height: 100vh;
  font-family: Montserrat, Cairo;
}
.oil-service {
  @extend .service;
  justify-content: flex-start;
  background-color: #283a47;
  align-items: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-top: 1em;
  &-title {
    color: white;
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 5em;

    @include sm-mobile {
      padding: 0px;
    }

    @include tablet {
      padding: 0em 1em;
    }

    @include sm-laptop {
      padding: 5em 5em;
    }
  }
  &-core {
    @include sm-mobile {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: center;
      width: 100vw;
    }

    @include tablet {
      width: 90vw;
    }

    @include sm-laptop {
      width: 85vw;
    }

    &-value2 {
      display: flex;
      width: 100%;
      flex-wrap: wrap-reverse;
      padding-top: 6em;
      @include sm-mobile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-flow: column-reverse;
      }
      @include tablet {
        flex-direction: column;
        align-items: center;
        flex-flow: column-reverse;
        justify-content: center;
      }

      @include sm-laptop {
        flex-flow: column;
        flex-direction: row;
        text-align: left;
        align-items: flex-start;
        justify-content: space-between;
      }
      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 2em;
        width: 40%;
        @include sm-mobile {
        }

        @include tablet {
          align-items: center;
          justify-content: center;
        }

        @include sm-laptop {
          align-items: flex-start;
        }

        img {
          border-radius: 16px;
          @include sm-mobile {
            width: 20em;
            max-width: 300px;
          }

          @include tablet {
            width: 20em;
            max-width: 480px;
          }

          @include sm-laptop {
            width: 20em;
            max-width: 750px;
          }
        }
      }
      &-about {
        display: flex;
        flex-direction: column;
        &-main {
          font-size: 30px;
          color: white;
        }
        &-notes {
          padding-top: 8px;
          text-align: left;
          color: white;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          font-weight: lighter;

          @include sm-mobile {
            width: 98vw;
            line-height: 20px;
            font-size: 18px;
            text-align: center;
          }

          @include tablet {
            width: 85vw;
            line-height: 25px;
            font-size: 18px;
          }

          @include sm-laptop {
            line-height: 25px;
            font-size: 20px;
            width: 40vw;
            text-align: justify;
          }
        }
      }
    }

    &-value1 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      @include sm-mobile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      @include tablet {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      @include sm-laptop {
        flex-direction: row;
        text-align: left;
        align-items: flex-start;
        justify-content: space-between;
      }

      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 2em;
        width: 40%;
        @include sm-mobile {
        }

        @include tablet {
          align-items: center;
          justify-content: center;
        }

        @include sm-laptop {
          align-items: flex-start;
        }

        img {
          border-radius: 16px;
          @include sm-mobile {
            width: 20em;
            max-width: 300px;
          }

          @include tablet {
            width: 20em;
            max-width: 480px;
          }

          @include sm-laptop {
            width: 20em;
            max-width: 750px;
          }
        }
      }
      &-about {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: center;
        &-main {
          font-size: 30px;
          color: white;
          @include sm-mobile {
            width: 98vw;
            line-height: 35px;
            font-size: 30px;
            text-align: center;
          }

          @include tablet {
            width: 90vw;
            line-height: 25px;
            font-size: 18px;
          }

          @include sm-laptop {
            line-height: 25px;
            font-size: 20px;
            text-align: left;
            width: 40vw;
          }
        }
        &-notes {
          padding-top: 8px;
          text-align: left;
          color: white;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          font-weight: lighter;

          @include sm-mobile {
            width: 98vw;
            line-height: 20px;
            font-size: 18px;
            text-align: center;
          }

          @include tablet {
            width: 85vw;
            line-height: 25px;
            font-size: 18px;
          }

          @include sm-laptop {
            line-height: 25px;
            font-size: 20px;
            width: 40vw;
            text-align: justify;
          }
        }
      }
    }
  }
  &-list {
    width: 100vw;
    color: white;
    &-desc {
      @include sm-mobile {
        width: 100vw;
        padding-left: 0em;
        font-size: 18px;
        padding-top: 5em;
        text-align: center;
      }

      @include tablet {
        padding-top: 5em;
        text-align: center;
        width: 100vw;
        padding-left: 0em;
      }

      @include sm-laptop {
        padding-top: 10em;
        font-size: 22px;
        padding-left: 5em;
        text-align: left;
        width: 65vw;
      }
    }
    &-holder {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      @include sm-mobile {
        padding-top: 2em;
      }

      @include tablet {
        padding-top: 3em;
      }

      @include sm-laptop {
        padding-top: 5em;
        padding-bottom: 5em;
      }
      &-icons {
        display: flex;
        width: 85%;
        flex-wrap: wrap;
        padding-top: 1em;
        // height: 60vh;
        // align-items: center;
        @include sm-mobile {
          justify-content: center;
        }

        @include tablet {
          justify-content: center;
        }

        @include sm-laptop {
          justify-content: start;
        }
        img {
          padding: 8px;
          margin: 16px;
          min-width: 240px;
          max-width: 240px;
          border: 0.1px dashed #b1c1c0;
          &:hover {
            max-width: 250px;
            background-color: #6e5f77;
          }
        }
      }
    }
  }
}

.software-service {
  justify-content: center;
  align-items: center;
  display: flex;
  @extend .service;
}

.software-container {
  min-height: 100vh;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  &-title {
    padding: 2em 0 0.5em 0;
    font-size: 30px;
    font-family: 'Cairo';
    font-weight: 600;
  }
  &-description {
    font-size: 16px;
    font-family: Cairo;
    line-height: 18px;
    padding: 0em 0 5em 0em;
    @include sm-mobile {
      width: 90%;
    }

    @include tablet {
      width: 70%;
    }

    @include sm-laptop {
      width: 50%;
    }
  }
  &-top-img {
    width: 100%;
    img {
      @include sm-mobile {
        width: 90%;
      }

      @include tablet {
        width: 70%;
      }

      @include sm-laptop {
        width: 40%;
      }
    }
  }
}


.tools {
  display: flex;
  padding-top: 3em;
  flex-direction: row;
  justify-content: center;
  &-item {
    display: flex;
    min-height: 100px;
    min-width: 100px;
    padding: 0 15px 0 15px;
    flex-direction: column;
    justify-content: space-between;
    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        width: 100px;
       
      }
    }
    &-title {
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 600;
    }
  }
  &-description {
    font-size: 18px;
    font-family: Montserrat;
  }
}