@import '../../break-points.scss';

// .contact {
//   position: absolute;
//   right: 0;
//   left: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   &-details {
//     position: absolute;
//     background-color: rgba(0, 0, 0, 0.3);
//     top: 0;
//     // height: 100%;
//     border-radius: 16px;
//     @include sm-mobile {
//       top: 10px;
//     }
//     @include tablet {
//       top: 10px;
//     }
//     @include sm-laptop {
//       width: 70%;
//       top: unset;
//     }
//     &-title {
//       // padding-top: 1em;
//       font-family: Cairo;
//       color: white;
//       font-weight: bold;
//       text-align: center;
//       @include sm-mobile {
//         font-size: 22px;
//       }
//       @include tablet {
//         font-size: 30px;
//       }
//       @include sm-laptop {
//         font-size: 30px;
//       }
//     }
//     &-contacts {
//       display: flex;
//       justify-content: space-evenly;
//       align-items: center;
//       flex-wrap: wrap;
//       @include sm-mobile {
//         margin-top: 1em;
//       }
//       @include tablet {
//         margin-top: 2em;
//       }
//       @include sm-laptop {
//         margin-top: 1em;
//       }
//       &-holder {
//         @include sm-mobile {
//           width: 100vw;
//         }
//         @include tablet {
//           width: 100vw;
//         }
//         @include sm-laptop {
//           width: unset;
//         }
//         &-item {
//           line-height: 35px;
//           padding: 10px;
//           @include sm-mobile {
//             text-align: center;
//             padding: 10px;
//           }
//           @include tablet {
//             text-align: center;
//           }
//           @include sm-laptop {
//             text-align: left;
//           }
//           &-name {
//             font-family: Cairo;
//             font-weight: bold;
//             color: orange;
//             cursor: pointer;
//             &:hover {
//               color: orange;
//             }
//             @include sm-mobile {
//               font-size: 16px;
//             }
//             @include tablet {
//               font-size: 18px;
//             }
//             @include sm-laptop {
//               font-size: 18px;
//             }
//           }
//           &-note {
//             font-family: Cairo;
//             line-height: 20px;
//             // color: #3b8646;
//             font-weight: 400;
//             color: white;
//             @include sm-mobile {
//               font-size: 14px;
//             }
//             @include tablet {
//               font-size: 14px;
//             }
//             @include sm-laptop {
//               font-size: 16px;
//             }
//           }
//         }
//       }
//     }
//   }
// }
.shadow {
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 6px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.col-data {
  @include sm-mobile {
    width: 100%;
    min-height: 100vh;
  }
  @include tablet {
    font-size: 14px;
    width: 50%;
  }
  @include sm-laptop {
    width: 50%;
    font-size: 16px;
  }
}

.contact {
  min-height: 100vh;
  height: 100%;
  min-width: 100vw;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  @include sm-mobile {
    flex-direction: column;
  }
  @include tablet {
    flex-direction: column;
  }
  @include sm-laptop {
    flex-direction: row;
  }
  &-col1 {
    @extend .col-data;
    background-repeat: no-repeat;
    height: 100% !important;
    background-size: cover;
    object-position: right;
    background: url('../../assets/contact.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @include sm-mobile {
      width: 100% !important;
    }
    @include tablet {
      width: 100% !important;
    }
    @include sm-laptop {
      width: 60% !important;
    }
  }
  &-col2 {
    @extend .shadow;
    @extend .col-data;
    position: relative;
    background-color: white !important;
    padding-top: 4rem;
    min-height: unset !important;
    @include sm-mobile {
      padding-bottom: 4rem;
      width: 100% !important;
    }
    @include tablet {
      padding-bottom: 5rem;
      width: 100% !important;
    }
    @include sm-laptop {
      padding-bottom: 0;
      width: 40% !important;
    }
    &-holder {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 1.2rem;
      &-name {
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 600;
        color: #062131;
        text-align: center;
      }
      &-number {
        font-size: 15px;
        font-family: 'Cairo';
        color: #062131;
      }
      &-email {
        font-size: 15px;
        font-family: 'Cairo';
        line-height: 13px;
        color: #062131;
      }
    }
  }
}
