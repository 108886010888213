@import "./break-points.scss";

.show-menus {
  // top: 0;
  bottom: 0;
  z-index: 9999999;
  position: fixed;
  height: calc(100vh - 180px);
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  background-color: white;
  &-mobile {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60vh;
      justify-content: space-evenly;
  }

  @include sm-mobile {
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }

  @include tablet {
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }
  @include sm-laptop {
    height: calc(100vh - 180px);
  }
}


.white {
  background-color: white !important;
}
// body {
//   .vp-center {
//     height:  unset !important;
//   }
// }
.experience-component-video-holder-player {
  div {
    iframe {

      @include sm-mobile {
        height: 30vh !important;
      }
    
      @include tablet {
        height: 50vh !important;
      }
      
      @include sm-laptop {
        height: 100% !important;
      }
    }
  }
}