@import '../../break-points.scss';

.home-css {
    background-color: black !important;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
    left: 0;
    &-front {
    text-align: center;
    font-family: Cairo;
    line-height: 30px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    &-title {
        width: 60%;
        padding-bottom: 10%;
        text-align: center;
        justify-content: center;
        height: 100px;
        // max-height: 100px;
        // min-height: 100px;
        display: flex;
        @include sm-mobile {
            width: 80%;
            font-size: 20px;
            font-weight: 900;
        }
        @include tablet {
            width: 80%;
            font-size: 30px;
        }
        @include sm-laptop {
            font-size: 50px;
            font-weight: 600;
            width: 60%;
        }
    }
    @include sm-mobile {
        padding-top: 15%;
        font-size: 20px;
    }
    @include tablet {
        padding-top: 15%;
        font-size: 25px;
    }

    @include sm-laptop {
        padding-top: 10%;
        line-height: 50px;
    }
  }
}
