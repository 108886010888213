@import "../../break-points.scss";

.show-person {
  height: 100vh !important;
  border: none !important;
  outline: none;
  background-color: white;
  border-right: 1px solid rgb(234, 231, 231) !important;
  
  @include sm-mobile {
    width: 85vw;
  }
  @include tablet {
    width: 85vw;
  }

  @include sm-laptop {
    width: 50vw;
    bottom: 0;
  }

  &-content {
    width: 100%;
    height: 100%;
    &-head {
      height: 40vh;
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-name {
        font-size: 30px;
        font-family: Cairo;
        color: white;
      }
      &-role {
        font-size: 12px;
        font-family: Montserrat;
        color: white;
      }
    }
    &-body {
      height: 100%;
      color: black;
      padding: 5%;
      font-family: Montserrat;
      font-weight: 200;
      overflow-y: auto;
      @include sm-mobile {
        text-align:left;
        font-size: 12px;
        line-height: 15px;
      }
      @include tablet {
        line-height: 22px;
        margin-top: 5%;
        text-align:left;
        font-size: 15px;
      }
      
      @include sm-laptop {
        line-height: 28px;
        text-align: left;
      }
    }
  }
}

.ReactModal__Overlay {
  background-color: transparent !important;
}
