@import "../../break-points.scss";

.executives {
  position: absolute;
  left: 0;
  right: 0;
  background-color: white;

  @include sm-mobile {
    padding-top: 20%;
  }
  @include tablet {
    padding-top: 20%;
  }
  @include sm-laptop {
    padding-top: 10%;
  }
  &-title {
    font-family: Montserrat;
    font-weight: 600;
    padding: 5%;
    @include sm-mobile {
      font-size: 22px;
    }
    @include tablet {
      font-size: 22px;
    }
    @include sm-laptop {
      font-size: 30px;
    }
  }
  &-inner {
    position: relative;
    padding-top: 10%;
    min-height: 100vh;
    &-body {
      background-color: #1a1a1a;
      height: 100%;
      min-height: 100vh;
      justify-content: space-around;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &-top-wave {
      position: relative;
      width: 100%;
      &-img {
        width: 100%;
        max-width: 100vw;
        position: absolute;
        width: 100vw;
        bottom: 0;
      }
    }
    &-bottom-wave {
      position: relative;
      width: 100%;
      background-color: blue;
      &-img {
        position: absolute;
        bottom: -80;
        max-width: 100vw;
        width: 100vw;
      }
    }
    &-bg {
      width: 100%;
      padding-top: 18px;
      position: absolute;
      @include sm-mobile {
        transform: translateY(-100%);
      }
      @include tablet {
        transform: translateY(-100%);
      }
      @include sm-laptop {
        transform: translateY(-100%);
      }
    }
  }
  &-bottom {
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 6em;
  }
}
