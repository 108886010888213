@import "../../break-points.scss";

@include sm-mobile {
  .desktop-navigator {
    display: none;
  }

  .mobile-navigator {
    width: 100%;
    &-show-menus {
      height: 100vh;
      position: fixed;
      bottom: 0;
      background-color: transparent !important;
    }
  }
}

@include tablet {
  .desktop-navigator {
    display: none;
  }

  .mobile-navigator {
    display: block;
  }
}

@include sm-laptop {
  .mobile-navigator {
    display: none;
  }

  .desktop-navigator {
    display: block;
  }
}

.mobile-navigator {
  &-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      position: absolute;
      background: rgba(0,0,0,0.9);
      color: white;
      z-index: 9999999999999;
      left: 0;
      right: 0;
      font-size: 10px;
      margin-top: 8px;
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 18px;
      text-align: center;
      font-weight: 700;
      font-family: Cairo;
    }
    &-burger {
      height: 30px;
      padding-right: 2%;
      width: 30px;
    }
  }
}

.desktop-navigator {
  &-holder {
    display: flex;
    align-items: center;
    justify-content: start;
    &-links {
      display: flex;
      width: 55vw;
      justify-content: center;
      align-items: center;
    }

    &-icon {
      min-width: 50px;
      width: 40%;
      cursor: pointer;
      display: flex;
      text-align: center;
      align-items: center;
      // holder
      &-title {
        font-weight: 600;
        font-family: Montserrat;

        @include sm-mobile {
          width: 100vw;
        }
        @include tablet {
          width: 100vw;
        }
        @include sm-laptop {
          width: unset;
        }
      }
    }
  }
}

.logo {
  width: 30vw;
  &-png {
    @include sm-mobile {
      width: 130px;
    }
    @include tablet {
      width: 130px;
    }
    @include sm-laptop {
      width: 180px;
    }
  }
}

.navigator-holder {
  top: 0px;
  height: 120px;
  display: flex;
  align-items: center;

  @include sm-mobile {
    height: 80px !important;
  }

  @include tablet {
    height: 80px !important;
  }
  @include sm-laptop {
    height: 150px !important;
  }
}

.link-style {
  font-size: 15px;
  text-decoration: none;
  color: #b1c1c0;
  padding: 18px;
  display: flex;
  align-items: center;
  height: 45px;
  text-align: center;
  font-family: Cairo;
  font-weight: 400;
  line-height: 20px;
}

.active-link {
  color: #3b8646;
  font-size: 18px;
  font-weight: 600;
  font-size: 20px;
}

.mobile-active {
  color: #3b8646;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 999999999999;
  font-size: 18px;
  font-weight: 600;
  font-family: "Cairo";
}
