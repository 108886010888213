@import "../../break-points.scss";

.about-us-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  position: absolute;
  .about-us-container-part1 {
    @include sm-mobile {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // padding-top: 5em;
      display: flex;
      flex-direction: column-reverse;
      height: 110vh;
      z-index: 1;
      width: 100vw;
    }
    @include tablet {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column-reverse;
    }
    @include sm-laptop {
      display: flex;
      height: 110vh;
      width: 100vw;
      flex-direction: row;
      justify-content: space-evenly;
    }

    &-img {
      @include sm-mobile {
        height: 70vh;
        width: fit-content;
      }
      @include tablet {
        height: 60vh;
        width: fit-content;
      }
      @include sm-laptop {
        height: 100vh;
        width: 100vw;

      }
      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
