@import "../../break-points.scss";


.development {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  &-description {
      padding: 2%;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
      font-size: 20px;
      flex-direction: column;
      &-back {
          font-family: Montserrat;
            @include sm-mobile {
              font-size: 30px;
            }
            @include tablet {
                font-size: 30px;
            }
            @include sm-laptop {
                font-size: 40px;
            }
      }
      &-ops {
          font-family: Cairo;
          @include sm-mobile {
            font-size: 15px;
          }
          @include tablet {
              font-size: 15px;

          }
          @include sm-laptop {
            font-size: 20px;
          }
      }
  }
}
