.carousel-container {
  max-width: 100vw !important;
  height: 100vh;
  max-width: 100vw !important;
  &-item {
    width: 100vw !important;
    height: 100vh !important;
    background-color: #141516;
    padding: 0 !important;
    margin: 0 !important;
    // padding-bottom: 3em;
    // padding-top: 3em;
  }
}
.carousel {
  .thumbs-wrapper {
    display: none;
  }
}

iframe {
  margin: 0 !important;
  width: 100vw;
}
// iframe {

//   .vp-controls {
//     display: unset !important;
//   }
  .vp-controls {
    display: unset !important;
    position: absolute;
    bottom: 1em;
    left: 1em;
    right: 1em;
    height: 0;
    opacity: 1;
    z-index: 14;
    display: none !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
// }