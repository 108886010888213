@import "../../break-points.scss";

.experience-container {
  left: 0;
  right: 0;
  position: absolute;
  background-color: white;
  &-experience-title {
    text-align: center;
    line-height: 1em;
    font-size: 60px;
    font-family: Cairo;
    padding-top: 1em;
  }
  &-software {
    background-color: #1a1a1a;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Montserrat;
    padding-top: 10em;
    color: white;
    @include sm-mobile {
      margin-top: 0em;
    }
    @include tablet {
      margin-top: 2em;
    }
    @include sm-laptop {
      margin-top: 5em;
    }
    &-title {
      font-family: Cairo;
      padding: 3em 0 1em 0em ;
      line-height: 30px;
      text-align: center;
      @include sm-mobile {
        font-size: 25px;
      }
      @include tablet {
        font-size: 30px;
      }
      @include sm-laptop {
        font-size: 40px;
      }
    }
    &-description {
      font-size: 16px;
      font-family: Cairo;
      line-height: 25px;
      font-weight: lighter;
      @include sm-mobile {
        width: 95vw;
      }
      @include tablet {
        width: 80vw;
      }
      @include sm-laptop {
        width: 60vw;
      }
      text-align: center;
      padding: 1em 0 5em;
    }
  }
  &-clients {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include sm-mobile {
      height: 50vh;
      min-height: 50vh;
    }
    @include tablet {
      height: 50vh;
      min-height: 80vh;
    }
    @include sm-laptop {
      height: 120vh;
    }
    &-img {
      // padding-top: 5%;
      img {
        @include sm-mobile {
          width: 95vw;
        }
        @include tablet {
          width: 80vw;
        }
        @include sm-laptop {
          width: 75vw;
        }
      }
    }
    &-title {
      padding-bottom: 5%;
      padding-top: 10%;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      &-header {
        font-family: sans-serif;
        @include sm-mobile {
          padding-top: 1em;
          font-size: 30px;
        }
        @include tablet {
          padding-top: 10%;
          
          font-size: 40px;
        }
        @include sm-laptop {
          font-size: 60px;
          padding-top: 0%;
        }
      }
      &-sub {
        font-size: 15px;
        font-family: Cairo;
        line-height: 30px;
        @include sm-mobile {
          padding-top: 10%;
          text-align: justify;
          line-height: 25px;
          width: 90%;
        }
        @include tablet {
          width: 80%;
          padding-top: 10%;
          text-align: center;
        }
        @include sm-laptop {
          width: 65%;
          padding-top: 2em;
          text-align: center;
        }
      }
    }
  }
}
  

.experience {
  &-sub {
    font-family: Montserrat;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @include sm-mobile {
      font-size: 14px;
      padding-top: 10em;
      line-height: 25px;
    }
    @include tablet {
      font-size: 16px;
      padding-top: 5em;
    }
    @include sm-laptop {
      padding-top: 5em;
      line-height: 25px;
      font-size: 17px;
      height: 100vh;
    }
    &-content {
      padding-top: 0.5em;
      @include sm-mobile {
        width: 90w;
        text-align: center;
      }
      @include tablet {
        width: 85vw;
        text-align: justify;
      }
      @include sm-laptop {
        text-align: justify;
        width: 88vw;
      }
    }
  }
}