// Small tablets and large smartphones (landscape view)
$screen-sm-mobile: 320px;

$screen-md-mobile: 375px;

$screen-lg-mobile: 425px;

$screen-tablet: 768px;

$screen-sm-laptop: 1024px;

$screen-lg-laptop: 1440px;

$screen-wide: 2560px;

@mixin sm-mobile {
  @media (min-width: #{$screen-sm-mobile}) {
    @content;
  }
}

@mixin md-mobile {
  @media (min-width: #{$screen-md-mobile}) {
    @content;
  }
}

@mixin lg-mobile {
  @media (min-width: #{$screen-lg-mobile}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$screen-tablet}) {
    @content;
  }
}

@mixin sm-laptop {
  @media (min-width: #{$screen-sm-laptop}) {
    @content;
  }
}

@mixin lg-laptop {
  @media (min-width: #{$screen-lg-laptop}) {
    @content;
  }
}

@mixin wide {
  @media (min-width: #{$screen-wide}) {
    @content;
  }
}
